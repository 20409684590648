import React, { FC, ReactElement, useEffect, useMemo, useState } from "react";
import useReactRouter from "use-react-router";
import styled from "styled-components";
import { CardData, ScoreEvent, TeamPosition } from "components/organisms/ScoreCard/types";
import qs from "qs";
import firebase from "firebase";
import { Timeline } from "models/timeline";
import { Game } from "models/game";
import ScoreCardForRedHurricanes from "components/organisms/ScoreCard/RedHurricanes";
import ScoreCardForUrayasuDRocks from "components/organisms/ScoreCard/UrayasuDRocks";
import {
  fetchLeagueTeam,
  fetchTeamMaster,
  fetchTeamMasterPlayerByLeagueData
} from "assets/js/firebase";
import { TMID_RED_HURRICANES, TMID_URAYASU_DROCKS } from "models/teamMaster";
import { getGameTotalScore } from "libs/score/getGameTotalScore";

const parseTimeline = (
  timelineData: Timeline,
  gameData: Game,
  teamPosition: "home" | "away"
): { leaguePlayerId: string; leagueTeamId: string } => {
  if (teamPosition === "home") {
    return {
      leaguePlayerId: timelineData.player.home as string,
      leagueTeamId: gameData.team_homeId ?? ""
    };
  } else {
    return {
      leaguePlayerId: timelineData.player.away as string,
      leagueTeamId: gameData.team_awayId ?? ""
    };
  }
};

const formatGameStatus = (gameData: Game, timeline: Timeline, teamMasterId: string): string => {
  let isFirstHalf = false;
  const timeMin = parseInt(timeline.time.split(".")[0], 10);
  // ピリオド形式の場合は、firstHalf 以外は後半扱いする
  // 前半後半以外のステータスがあるスポーツに導入する際に改修する必要がある
  if (gameData?.periods?.periods?.length) {
    // ピリオド共通化対応
    const currentPeriod = gameData?.periods?.periods?.find(
      period => period.id === timeline.periodId
    );
    if (currentPeriod?.name_ja) {
      return `${currentPeriod.name_ja} ${timeMin}分`;
    }
  }
  if (timeline.isNewGamePeriodGame && timeline.periodId) {
    isFirstHalf = timeline.periodId === "firstHalf";
  } else {
    isFirstHalf = timeline.isFirstHalf ?? false;
  }
  // チーム毎に表記方法が異なる
  switch (teamMasterId) {
    case TMID_RED_HURRICANES:
      return isFirstHalf ? `前半${timeMin}分` : `後半${timeMin}分`;
    case TMID_URAYASU_DROCKS:
      // DRocks
      return isFirstHalf ? `1st ${timeMin}min` : `2nd ${timeMin}min`;
    default:
      return isFirstHalf ? `1st ${timeMin}min` : `2nd ${timeMin}min`;
  }
};

const formatGameName = (gameData: Game, teamMasterId: string): string => {
  // チーム毎に表記方法が異なる
  switch (teamMasterId) {
    case TMID_RED_HURRICANES:
      return gameData.info_leagueText ?? "";
    case TMID_URAYASU_DROCKS:
      // DRocks
      return gameData.info_leagueText_en ?? gameData.info_leagueText ?? "";
    default:
      return gameData.info_leagueText ?? "";
  }
};

const makeCardData = async (
  sportsId: string,
  leagueId: string,
  gameId: string,
  timelineId: string,
  teamPosition: TeamPosition,
  teamMasterId: string
): Promise<CardData> => {
  const teamMaster = await fetchTeamMaster(sportsId, teamMasterId);
  const timelineData: Timeline = (
    await firebase
      .database()
      .ref(`timeline/${sportsId}/${leagueId}/${gameId}/${timelineId}`)
      .once("value")
  ).val();
  const gameData = await firebase
    .firestore()
    .collection(`sportsData/${sportsId}/league/${leagueId}/games`)
    .doc(gameId)
    .get()
    .then(data => data.data() as Game);
  if (!gameData.team_homeId || !gameData.team_awayId) {
    throw new Error("hoge!!");
  }
  const { leaguePlayerId, leagueTeamId } = parseTimeline(timelineData, gameData, teamPosition);
  // leaguePlayer は、ProGraphics の画像URLなど、
  // teamMasterPlayer と同期されていない場合があるため、teamMasterPlayer を参照する
  const player = await fetchTeamMasterPlayerByLeagueData(
    sportsId,
    teamMasterId,
    leagueId,
    leagueTeamId,
    leaguePlayerId
  );
  const homeLeagueTeam = await fetchLeagueTeam(sportsId, leagueId, gameData.team_homeId);
  const awayLeagueTeam = await fetchLeagueTeam(sportsId, leagueId, gameData.team_awayId);
  const { homeScore, awayScore } = await parseScoreData(
    sportsId,
    leagueId,
    gameId,
    timelineId,
    gameData
  );
  return {
    bgImg: teamMaster.bgImgUrlWithProGraphicsNicePlay ?? "",
    playerName: player.name,
    playerNameEn: player.name_en ?? "",
    playerPhoto: player.photoUrlWithProGraphicsNicePlay,
    gameName: formatGameName(gameData, teamMasterId),
    gameStatus: formatGameStatus(gameData, timelineData, teamMasterId),
    playerPosition: player.positionList ? player.positionList[0].id : "",
    eventType: getEventType(timelineData),
    homeScore: homeScore,
    awayScore: awayScore,
    homeTeamIcon: homeLeagueTeam.logoUrl,
    awayTeamIcon: awayLeagueTeam.logoUrl,
    teamPosition: teamPosition
  } as CardData;
};

const getEventType = (timelineData: Timeline): ScoreEvent => {
  switch (timelineData.type) {
    case "try":
      return "try";
    case "dropgoal":
      return "dropGoal";
    case "penaltygoal":
      return "penaltyGoal";
    case "conversiongoal":
      return "conversionGoal";
    default:
      throw new Error(`invalid event type: ${timelineData.type}`);
  }
};

const parseScoreData = async (
  sportsId: string,
  leagueId: string,
  gameId: string,
  timelineId: string,
  gameData: Game
): Promise<{ homeScore: string; awayScore: string }> => {
  const timelineDict: { [id: string]: Timeline } = (
    await firebase.database().ref(`timeline/${sportsId}/${leagueId}/${gameId}`).once("value")
  ).val();
  const { latestPoints } = getGameTotalScore(
    timelineDict,
    sportsId,
    timelineId,
    gameData?.periods?.periods,
    gameData?.periodScores,
    gameData?.totalScore
  );

  return {
    homeScore: String(latestPoints.home),
    awayScore: String(latestPoints.away)
  } as {
    homeScore: string;
    awayScore: string;
  };
};

type QueryParam = {
  sportsId: string;
  leagueId: string;
  gameId: string;
  timelineId: string;
  teamPosition: TeamPosition;
  teamMasterId: string;
};

/**
 * データ取得ロジックのエラーハンドリングについては、
 * data が正常にロードされる(nullではなくなる)場合にしか
 * div.generatedCard は生成されないので、
 * このページを表示してスクショする puppeteer 側で dom を見つけられずに
 * タイムアウトしてエラーハンドリングが行われるため、ここでは特に行わない。
 * 別途リファクタとしてエラーハンドリングを検討する
 */

const GenScoreCard: FC = (): ReactElement => {
  const [data, setData] = useState<CardData | null>(null);
  const { location } = useReactRouter();
  const url = decodeURIComponent(location.search);
  const { sportsId, leagueId, gameId, timelineId, teamPosition, teamMasterId } = qs.parse(
    url.replace("?", "")
  ) as QueryParam;

  const renderScoreCard = (
    teamMasterId: string,
    cardData: CardData | null
  ): ReactElement | null => {
    if (cardData === null) return null;
    switch (teamMasterId) {
      case TMID_RED_HURRICANES:
        return (
          <div className="generatedCard">
            <ScoreCardForRedHurricanes data={cardData} />
          </div>
        );
      case TMID_URAYASU_DROCKS:
        return (
          <div className="generatedCard">
            <ScoreCardForUrayasuDRocks data={cardData} />
          </div>
        );
      default:
        return null;
    }
  };
  const renderScoreCardMemo = useMemo(() => {
    return renderScoreCard(teamMasterId, data);
  }, [teamMasterId, data]);

  useEffect(() => {
    const load = async () => {
      const res = await makeCardData(
        sportsId,
        leagueId,
        gameId,
        timelineId,
        teamPosition,
        teamMasterId
      );
      setData(res);
    };
    load();
  }, [sportsId, leagueId, gameId, timelineId, teamPosition, teamMasterId]);
  return <Wrapper>{renderScoreCardMemo}</Wrapper>;
};

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  .generatedCard {
    width: fit-content;
    height: fit-content;
  }
`;

export default GenScoreCard;
