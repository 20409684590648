import firebase from "firebase";
import { Team } from "./team";
import { Movie } from "./movie";
import { ShowSummary } from "./superFormula/timingData";

type Timestamp = firebase.firestore.Timestamp;

export type ForeignLangType = "en";
export type LangType = "ja" | ForeignLangType;

// "progress":試合開始/終了 "timeline": タイムラインからの自動解説 "freeCommentary": 自由解説
export type AllowTweetType = "progress" | "timeline" | "freeCommentary";
export type AllowTweetPosition = "home" | "away" | "neutral" | "multiTeam";

// 試合解説をツイート自動連携する設定
export type AllowTweet = {
  position: AllowTweetPosition; //実装の必要がでてきたら追加
  adminUid: string;
  types: AllowTweetType[];
  opponentTimelineTypes?: "all" | "point";
  tweetHeaderText?: string; //本試合個別設定のヘッダフッタ. 未設定なら共通設定を使う
  tweetFooterText?: string;
  isImmediateDelivery?: boolean; // 通常はN分待って配信するが、即時配信したい設定

  teamId?: string; // 多人数競技ようにteamIdを追加 positionがmultiTeamの場合にセットされる想定
};

export type Game = {
  id?: string;
  homeTeam?: Team | null;
  awayTeam?: Team | null;
  isPublic: boolean;
  isDisableSearch?: boolean; //Algoliaのデータ連携の判定で使用
  isOnlyResult?: boolean;
  isForceCommentFromTimeline?: boolean; //このフラグがあれば、試合後でも強制連携できるように
  isDisallowCoordination?: boolean; // データ連携用フラグ trueだとデータ連携OFFでfalseだとデータ連携ON
  isDisallowStartingMemberCoordination?: boolean;
  isDenyComment?: boolean; // 昔は isAllowUserCommentを利用していたがこちらのフィールドに変更済み
  isNotAddAutoCommentStats?: boolean;
  isNotAddAutoCommentTimeline?: boolean;
  isHiddenTimeline?: boolean;
  isShowStats?: boolean;
  headerTextColumnDesign?: any;
  hasCommentForeignLang?: ForeignLangType[];
  hasCommentary?: boolean;
  info_commentaryText: string;
  info_commentaryText_en?: string;
  info_datetime: Timestamp | null;
  info_leagueText: string | null;
  info_leagueText_en?: string | null;
  info_placeName: string | null;
  info_placeName_en?: string | null;
  placeId?: string; //　会場マスタのID
  placeUrl?: string | null; //試合個別画像 最優先で表示される画像
  defaultPlaceUrl?: string | null; //デフォルトの背景画像 会場マスタに背景画像が存在しない場合に表示される画像
  info_broadcast?: string | null;
  info_broadcast_en?: string | null;
  info_tweetInfo?: string | null;
  info_tweetInfo_en?: string | null;
  infringements?: { [id: string]: { away: number; home: number } };
  member_starting?: MemberInfo[];
  member_starting_away?: { playerId?: string | null; positionName?: string | null }[] | [];
  member_starting_home?: { playerId?: string | null; positionName?: string | null }[] | [];
  member_defence_away?: { playerId?: string | null; positionName?: string | null }[] | [];
  member_defence_home?: { playerId?: string | null; positionName?: string | null }[] | [];

  member_reserve?: MemberInfo[];
  member_reserve_away?: { playerId?: string | null; positionName?: string | null }[] | [];
  member_reserve_home?: { playerId?: string | null; positionName?: string | null }[] | [];
  secret_geopoint?: object | null;
  secret_passcode?: string | null;
  team_awayId: string | null;
  team_isAllowAwayGift?: boolean;
  team_awayName_ifEmptyTeamId?: string | null;
  team_awayName_ifEmptyTeamId_en?: string | null;
  team_homeId: string | null;
  team_isAllowHomeGift?: boolean;
  team_homeName_ifEmptyTeamId?: string | null;
  team_homeName_ifEmptyTeamId_en?: string | null;
  time_firstHalfStartTime?: Timestamp | null;
  time_secondHalfStartTime?: Timestamp | null;
  time_gameEndTime?: Timestamp | null; // 試合終了からN分までは解説配信するなどの処理がバックエンドで必要なので
  period_currentStatusId?: string | null; //試合ステータスのId. 試合開始前・終了後は未設定
  period_hasExtra?: boolean; // 延長戦実施の決定
  periodList?: Period[]; // 試合区切りデータ(開始時刻含む)
  time_isFirstHalfEnd?: boolean;
  time_isGameCancelled?: boolean;
  time_isGameEnd?: boolean;
  time_isGamePostponed?: boolean;
  time_pauseStartTime?: Timestamp | null;
  time_firstHalfDiffTimeMin?: number | null;
  time_firstHalfDiffTimeSec?: number | null;
  time_secondHalfDiffTimeMin?: number | null;
  time_secondHalfDiffTimeSec?: number | null;
  movies?: Movie[];
  nextGameToWatch?: GameId | null;
  isInningTop?: boolean; // 野球で使用 現在の回の表裏を表す。trueだと表、falseで裏
  homePitcher?: string; // 野球で使用 先攻チームの投手ID
  awayPitcher?: string; // 野球で使用 後攻チームの投手ID
  isDH?: boolean; // 野球で使用　指名打者があるときはtrue
  overtime?: number | null; // 野球で使用　延長回数  イニングの絶対数となる。延長1回の場合は10となる
  normalInnings?: number; //野球で使用　プレイボールが押された時点でのperiodList.lengthを保持。延長回を除く通常のイニング数として利用。

  fieldPositionSlider?: number; //アメフトで使用。fieldPositionSliderの位置。（0-100の値が入る）
  fieldPosition?: number; //アメフトで使用。グリッドの位置が入る。
  remainingYards?: number | null; //アメフトで使用。残りヤード数が入る。
  remainingTime?: number; //アメフトで使用。タイマーが止められた際の残り時間。（タイマーが止められた時に変更されることがある）
  isPrevYardsNotEmpty?: boolean | null; //アメフトで使用。前回送信したyardsが空でなかった場合trueを設定。

  periodTime?: number | null; //アメフトで使用 各ピリオドが終了するまでの時間
  isOT?: boolean; //アメフトで使用 延長回の有無 periodListに延長回の設定を行う
  periodOT?: number | null; //アメフトで使用 延長時間 period_hasExtraとは異なる（こちらはサッカーで使用）
  stopGameTime?: string; //アメフトで使用。タイマーが止められた時間。（ 9:52 タイマーに表示しているテキスト表現のまま）
  newGameTime?: string; //アメフトで使用。新しく設定された時間。（ 9:52 タイマーに表示しているテキスト表現のまま）

  // ↓ backendで利用(配信管理など)
  tweetedIds?: TweetDone[] | null;
  tweetFromAdminProcessingAt?: Timestamp | null; //処理中なら処理開始時刻をいれる
  commentedElgolazoIds?: string[];
  deliveredCandidateIds?: string[] | null;
  deliveredCandidateIds_en?: string[] | null;
  latestTimeline?: LatestTimeline | null;
  allowTweet?: { [adminUid: string]: AllowTweet }; //管理権限のユーザーのアカウントでツイートするためのデータ
  timeCapMinutes?: number | null; // フライングディスクで使用するタイムキャップ
  halfTimeCapMinutes?: number | null; // フライングディスクで使用するハーフタイムキャップ
  isTimeCapSet?: boolean; // フライングディスクで使用するタイムキャップのデータをタイムラインに送信したらtrueをセットする。再登録の防止に使用する
  isHalfTimeCapSet?: boolean; // フライングディスクで使用するハーフタイムキャップのデータをタイムラインに送信したらtrueをセットする。再登録の防止に使用する
  isLock?: boolean; // 試合画面の編集可否で使用
  time_eventStartTime?: Timestamp | null; // フライングディスクで使用するイベントタイマー開始時刻
  time_eventId?: string | null; // フライングディスクで使用するイベントID。管理画面に表示
  isDisableOfficialTweet?: boolean; //SpoLive公式アカウントへのツイート連携に使用する。trueならツイート対象としない

  //イベント対応用
  isEventScreen?: boolean;

  // 試合形式に関わる変更
  stageList?: Stage[]; //今までピリオドと言っていた予選、予選1、準決勝とかを定義する項目。ここに開始、終了状態も持たせる
  stageCurrentStatusIds?: string[]; //現在開始状態になっているStageのIDをセットしておく。この値でタイムラインは現在のステージを選択できるよにするイメージ

  // 多人数競技対応
  memberType?: null | "multi"; //multiなら複数チーム　nullや項目ない場合は既存のVSチーム形式
  teamIds?: string[]; //試合作成時にセットされるチームID。スタメンセットするときの候補となるID

  //SF対応
  showSummary?: ShowSummary;

  allowGift?: "allAllow" | "allDisallow" | "team" | null; // 多人数対応 リーグのデフォルトのスーパー応援設定
  // チーム毎のスーパー応援許可
  multiTeamDataList?: { [teamId: string]: MultiTeamInfo };
  // 多人数競技対応で追加 試合の終了日時
  info_endtime?: Timestamp | null;

  // 多人数競技で追加
  stageType?: StageType;
  isCheerBattleEnd?: boolean | null;
  cheerBattleEndTime: Timestamp;
  subscriptionContentTeamMasterId?: string | null;
  isSubscriptionContents?: boolean;
  groupId?: string;
  adminId?: string;
  adminType?: AdminType;
  adminLogoUrl?: string;
  adminColor?: string;

  // ピリオド共通化対応(SPO272)
  periods?: PeriodSetContract;
  periodTimeStamps?: PeriodTimeStampContract[];
  periodScores?: PeriodScoreContract[];
  totalScore?: TotalScore;
};

export type TotalScore = {
  homeScore: number;
  awayScore: number;
};

export type AdminType =
  | "teamAdmin"
  | "leagueAdmin"
  | "organizationAdmin"
  | "systemAdmin"
  | "groupAdmin"
  | undefined;

export type StageType = "default" | "useStage";

// 多人数競技対応　試合に持たせるチーム毎の設定。今後増えることも考慮してmap型にしておく
export type MultiTeamInfo = {
  isAllowGift: boolean;
};

// ラグビーで使用するスタメンの型定義
export type MemberInfo = {
  away: string;
  home: string;
  uniformNumber: string;
};
//今までピリオドと言っていた予選、予選1、準決勝とかの定義
export type Stage = {
  id: string;
  name: string;
  name_en: string; //※自動解説の英語の場合にタイトルにセットされるため必要
  level: number; //階層数。1始まりで親だと1、子だと2
  parentId?: string; //子の場合は親のIDをセット　parentIdを持っていたら親がいると判断
  childrenIds?: string[]; //親の場合は子のIDをセット　childrenIdsを持っていたら子がいると判断
  order: number; // 並び順
  teamMembers?: TeamMember; //子のみ値を持つ想定。複数チームに関わるとこ
  startedAt?: Timestamp; //開始時刻　親の場合は1個でも子が開始されると親も開始するイメージ
  finishedAt?: Timestamp; //終了時刻  親の場合は子が全て終了状態になると親も終了するイメージ
  planStartedAt?: Timestamp; //予定開始時刻
  planFinishedAt?: Timestamp; //予定開始時刻
  isGameCancel?: boolean; //試合中止
  isPostponed?: boolean; //試合延期
  diffTime?: number; //時間修正が入った際の修正分の時間（ミリ秒）startAtにdiffTimeを加算することで、試合の表示時間を作成することを想定
};
// 複数チームの定義
export type TeamMember = {
  [teamId: string]: StagePlayer[];
};
export type StagePlayer = {
  playerId: string;
  positionName: string;
};
export type StartingMember = {
  playerId?: string | null;
  positionName?: string | null;
};

export type GameId = {
  sportsId: string;
  leagueId: string;
  gameId: string;
};

export type TweetDone = {
  situationId: string;
  responseId: string | null;
  _tweetedAt?: Timestamp;
};

export type LatestTimeline = {
  isFirstHalf: boolean;
  time: string;
};

export type Infringement = {
  key: string;
  name: string;
  name_en: string;
  defaultValue?: number;
  is_show_gamecontent?: boolean;
  isTextInput?: boolean;
  unit?: string;
  aggregateKey?: string;
  aggregateAddPoint?: number;
};

export type SearchResultGame = {
  sportsId: string;
  leagueId: string;
  gameId: string;
  info_datetimeUnix: number;
  time_isGameEnd: boolean;
};

export type Period = {
  id: string; // nameなどは、SportsDic.periodListから参照して利用する
  startedAt?: Timestamp | null;
  diffTimeSec?: number; // その試合区切りのズレ補正。3分休憩してズレていたら3*60がはいる
  finishedAt?: Timestamp | null;
};

// ogpなどの試合カードに表示するための情報
export type GameStatus =
  | "cancelled"
  | "postponed"
  | "finished"
  | "hidden"
  | "today"
  | "during"
  | "live";

// 試合開始ならかならず0-0, 後半開始なら後半のプレーは集計しない
export type GameProgress = "gameStart" | "halfTime" | "gameEnd";

export type GameCheerBattleEnd = {
  isCheerBattleEnd: Boolean;
  cheerBattleEndTime: firebase.firestore.FieldValue;
};

export const getPeriodById = (periodList: Period[], id: string): Period | null => {
  for (const period of periodList) {
    if (period.id === id) {
      return period;
    }
  }
  return null;
};

export type Points = {
  home: number | "-";
  away: number | "-";
};

// ピリオドの仕様を共通化する仕組み導入 (SPO272)

export type PeriodTypeName = "Match" | "Break" | "Extra" | "Final tiebreaker";

export type PeriodEditType = "Full" | "OnlyDurationTime" | "Prohibited";

// ピリオド突入条件の対象リスト (ホームチームの得点、アウェイチームの得点、ホームチームのリード、アウェイチームのリード、どちらかのチームの得点、両チームの得点、、両チームの得点差)
export type PeriodEntrySubjectType =
  | "HomeScore"
  | "AwayScore"
  | "HomeLead"
  | "AwayLead"
  | "EitherScore"
  | "BothScore"
  | "ScoreDifference";

// ピリオド突入条件の条件リスト
export type PeriodEntryConditionType =
  | "Equal"
  | "NotEqual"
  | "GreaterThan"
  | "GreaterThanOrEqual"
  | "LessThan"
  | "LessThanOrEqual"
  | "Same"
  | "NotSame";

// ピリオドのループ条件の対象リスト（セット)
export type PeriodLoopSubjectType = "Period";

// ピリオドのループ条件の条件リスト
export type PeriodLoopConditionType =
  | "Equal"
  | "NotEqual"
  | "GreaterThan"
  | "GreaterThanOrEqual"
  | "LessThan"
  | "LessThanOrEqual";

export type TimerType = "CountDown" | "CountUp" | "None";

export type PeriodProceedCondition = {
  subject: PeriodEntrySubjectType;
  condition: PeriodEntryConditionType;
  value: number | null;
};

export type PeriodLoopCondition = {
  subject: PeriodLoopSubjectType;
  condition: PeriodLoopConditionType;
  value: number | null;
};

export type PeriodModel = {
  id: string; // Uuid
  name_ja: string;
  name_en: string;
  periodType: PeriodTypeName;
  showScoreDisplay: boolean;
  playEventIncluded: boolean;
  durationSeconds: number | null; // nullの場合は無制限
  timerType: TimerType;
  isPlayEventStatsTarget: boolean;
  proceedConditions: PeriodProceedCondition[];
  loopConditions: PeriodLoopCondition[];
  subPeriods: PeriodModel[];
};

export type PeriodSetModel = {
  id: string;
  name: string;
  description: string;
  editType: PeriodEditType;
  periods: PeriodModel[];
};

export type PeriodTimeStampModel = Record<
  string,
  {
    startedAt: Timestamp | null;
    diffTimeSec: number | null; // その試合区切りのズレ補正。3分休憩してズレていたら3*60がはいる
    finishedAt: Timestamp | null;
  }
>;

export type PeriodScoreModel = Record<string, { homeScore: number; awayScore: number }>;

export type PeriodContract = {
  id: string;
  name_ja: string;
  name_en: string;
  periodType: PeriodTypeName;
  durationSeconds: number | null; // nullの場合は無制限
  timerType: TimerType;
  proceedConditions: PeriodProceedCondition[];
  loopConditions: PeriodLoopCondition[];
  subPeriods: PeriodContract[];
};

export type PeriodSetContract = {
  id: string;
  name: string;
  description: string;
  periods: PeriodContract[];
};

export type PeriodTimeStampContract = {
  periodId: string; // periods配列内の指定periodのid
  startedAt: Timestamp | null;
  diffTimeSec: number | null; // その試合区切りのズレ補正。3分休憩してズレていたら3*60がはいる
  finishedAt: Timestamp | null;
};

export type PeriodScoreContract = {
  periodId: string; // periods配列内の指定periodのid
  homeScore: number;
  awayScore: number;
};

// 試合区切りの定義
export class MatchPeriod implements PeriodModel {
  // id は指定がなければ自動生成
  public readonly periodType = "Match";
  public readonly showScoreDisplay = true;
  public readonly playEventIncluded = true;
  public readonly isPlayEventStatsTarget = true;

  constructor(
    public readonly id: string,
    public readonly name_ja: string,
    public readonly name_en: string,
    public readonly durationSeconds: number | null,
    public readonly timerType: TimerType,
    public readonly proceedConditions: PeriodProceedCondition[],
    public readonly loopConditions: PeriodLoopCondition[],
    public readonly subPeriods: PeriodModel[]
  ) {}
}

export function isMatchPeriod(period: PeriodModel): period is MatchPeriod {
  return period.periodType === "Match";
}

// 休憩区切りの定義
export class BreakPeriod implements PeriodModel {
  public readonly periodType = "Break";
  public readonly showScoreDisplay = false;
  public readonly playEventIncluded = false;
  public readonly isPlayEventStatsTarget = false;

  constructor(
    public readonly id: string,
    public readonly name_ja: string,
    public readonly name_en: string,
    public readonly durationSeconds: number | null,
    public readonly timerType: TimerType,
    public readonly proceedConditions: PeriodProceedCondition[],
    public readonly loopConditions: PeriodLoopCondition[],
    public readonly subPeriods: PeriodModel[]
  ) {}
}

export function isBreakPeriod(period: PeriodModel): period is BreakPeriod {
  return period.periodType === "Break";
}

// 延長区切りの定義
export class ExtraPeriod implements PeriodModel {
  public readonly periodType = "Extra";
  public readonly showScoreDisplay = true;
  public readonly playEventIncluded = true;
  public readonly isPlayEventStatsTarget = true;

  constructor(
    public readonly id: string,
    public readonly name_ja: string,
    public readonly name_en: string,
    public readonly durationSeconds: number | null,
    public readonly timerType: TimerType,
    public readonly proceedConditions: PeriodProceedCondition[],
    public readonly loopConditions: PeriodLoopCondition[],
    public readonly subPeriods: PeriodModel[]
  ) {}
}

export function isExtraPeriod(period: PeriodModel): period is ExtraPeriod {
  return period.periodType === "Extra";
}

// PK戦区切りの定義
export class TiebreakPeriod implements PeriodModel {
  public readonly periodType = "Final tiebreaker";
  public readonly showScoreDisplay = false;
  public readonly playEventIncluded = true;
  public readonly isPlayEventStatsTarget = false;

  constructor(
    public readonly id: string,
    public readonly name_ja: string,
    public readonly name_en: string,
    public readonly durationSeconds: number | null,
    public readonly timerType: TimerType,
    public readonly proceedConditions: PeriodProceedCondition[],
    public readonly loopConditions: PeriodLoopCondition[],
    public readonly subPeriods: PeriodModel[]
  ) {}
}

export function isTiebreakPeriod(period: PeriodModel): period is TiebreakPeriod {
  return period.periodType === "Final tiebreaker";
}

// PeriodTypeに対応するクラスをマッピングする関数を定義
const createPeriodInstance = (p: any) => {
  const periodClasses: {
    [key: string]:
      | typeof MatchPeriod
      | typeof BreakPeriod
      | typeof ExtraPeriod
      | typeof TiebreakPeriod;
  } = {
    Match: MatchPeriod,
    Break: BreakPeriod,
    Extra: ExtraPeriod,
    "Final tiebreaker": TiebreakPeriod
  };

  const PeriodClass = periodClasses[p.periodType] || MatchPeriod;
  return new PeriodClass(
    p.id,
    p.name_ja,
    p.name_en,
    p.durationSeconds,
    p.timerType,
    p.proceedConditions,
    p.loopConditions,
    p.subPeriods ? p.subPeriods.map(createPeriodInstance) : []
  );
};

export const transformContractToPeriodSetModel = (
  contracts: PeriodSetContract[],
  editType: PeriodEditType
): PeriodSetModel[] => {
  return contracts.map(periodSet => {
    return {
      id: periodSet.id,
      name: periodSet.name,
      description: periodSet.description,
      editType: editType,
      periods: periodSet.periods.map(createPeriodInstance)
    };
  });
};

const createPeriodInstanceForContract = (p: any) => {
  return {
    id: p.id,
    name_ja: p.name_ja,
    name_en: p.name_en,
    durationSeconds: p.durationSeconds,
    timerType: p.timerType,
    proceedConditions: p.proceedConditions,
    loopConditions: p.loopConditions,
    periodType: p.periodType,
    subPeriods: p.subPeriods.map(createPeriodInstanceForContract)
  };
};

export const transformPeriodSetModelToContract = (
  periodSetModel: PeriodSetModel[]
): PeriodSetContract[] => {
  return periodSetModel.map(periodSet => {
    return {
      id: periodSet.id,
      name: periodSet.name,
      description: periodSet.description,
      periods: periodSet.periods.map(p => {
        return {
          id: p.id,
          name_ja: p.name_ja,
          name_en: p.name_en,
          durationSeconds: p.durationSeconds,
          timerType: p.timerType,
          proceedConditions: p.proceedConditions,
          loopConditions: p.loopConditions,
          periodType: p.periodType,
          subPeriods: p.subPeriods.map(createPeriodInstanceForContract)
        };
      })
    };
  });
};

export const transformContractToPeriodTimeStampModel = (
  contract: PeriodTimeStampContract[]
): PeriodTimeStampModel =>
  contract.reduce((model, { periodId, startedAt, finishedAt, diffTimeSec }) => {
    model[periodId] = { startedAt, finishedAt, diffTimeSec };
    return model;
  }, {} as PeriodTimeStampModel);

export const transformPeriodTimeStampModelToContract = (
  model: PeriodTimeStampModel
): PeriodTimeStampContract[] =>
  Object.entries(model).map(([periodId, { startedAt, finishedAt, diffTimeSec }]) => ({
    periodId,
    startedAt,
    finishedAt,
    diffTimeSec
  }));

export const transformContractToPeriodScoreModel = (
  contract: PeriodScoreContract[]
): PeriodScoreModel =>
  contract.reduce((model, { periodId, homeScore, awayScore }) => {
    model[periodId] = { homeScore, awayScore };
    return model;
  }, {} as PeriodScoreModel);

export const transformPeriodScoreModelToContract = (
  model: PeriodScoreModel
): PeriodScoreContract[] =>
  Object.entries(model).map(([periodId, { homeScore, awayScore }]) => ({
    periodId,
    homeScore,
    awayScore
  }));
