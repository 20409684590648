import { Timeline as TimelineType } from "models/timeline";
import { PeriodContract, PeriodScoreContract, TotalScore } from "models/game";
import { calculateTimelineData } from "assets/js/calculateTimelineData";
import { calcTotalScore } from "./util";

type GameTotalScore = {
  latestPoints: { home: number; away: number };
};

/**
 * 試合のピリオド毎のスコアを取得する。calculateTimelineDataをラップしている
 * @param timelines
 * @param sportsId
 * @param periodContract
 * @param periodScore
 * @returns
 */
export const getGameTotalScore = (
  timelines: { [id: string]: TimelineType },
  sportsId: string,
  specifiedTimelineId?: string,
  periodContract?: PeriodContract[],
  periodScore?: PeriodScoreContract[],
  totalScore?: TotalScore
): GameTotalScore => {
  const score = calcTotalScore(periodScore, totalScore);
  if (score) {
    return {
      latestPoints: score
    };
  }

  const timelinePoint = calculateTimelineData(
    timelines,
    sportsId,
    specifiedTimelineId,
    periodContract
  );
  return {
    latestPoints: timelinePoint.latestPoints
  };
};
