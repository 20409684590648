import React from "react";
import { useAsyncMemo } from "@spolive-team/hooks";
import { fetchTeamMaster } from "assets/js/firebase";
import useGameData from "hooks/use-gameData";
import { TeamMaster } from "models/teamMaster";
import { createContext, useContext } from "react";
import { Points } from "models/game";

export type GameContextValue = {
  sportsId: string;
  leagueId: string;
  gameId: string;
  locale: string;
  homePoint: number | undefined;
  awayPoint: number | undefined;
  homeTeamMaster?: TeamMaster;
  awayTeamMaster?: TeamMaster;
  firstHalf?: Points;
  lastHalf?: Points;
} & ReturnType<typeof useGameData>;

export const GameContext = createContext<GameContextValue>({
  homePoint: undefined,
  awayPoint: undefined,
  sportsId: "",
  leagueId: "",
  gameId: "",
  locale: "ja",
  loading: true,
  error: undefined,
  gameData: undefined,
  infoDateDay: "",
  infoDateDayOfWeek: "",
  infoDateTime: "",
  infoDateUtc: "",
  latestPoints: { home: 0, away: 0 },
  latestGamePoints: undefined,
  placeImageUrl: "",
  specifiedPlay: undefined,
  timelineData: undefined,
  leagueData: undefined
});

export const GameContextProvider = ({
  children,
  ...value
}: {
  children: React.ReactNode;
} & {
  sportsId: string;
  leagueId: string;
  gameId: string;
  locale: string;
  timelineId?: string;
  homePoint?: number;
  awayPoint?: number;
}) => {
  const { sportsId, leagueId, gameId, locale, homePoint, awayPoint, timelineId } = value;
  const data = useGameData({
    sportsId,
    leagueId,
    gameId,
    timelineId
  });
  const { gameData, specifiedPlay, latestPoints, latestGamePoints, timelineData } = data;

  const homeTeamMaster = useAsyncMemo(async () => {
    if (!gameData?.homeTeam?.masterTeamId) return;
    const teamMaster = fetchTeamMaster(sportsId, gameData?.homeTeam?.masterTeamId);
    return teamMaster;
  }, [gameData?.homeTeam?.masterTeamId]);

  const awayTeamMaster = useAsyncMemo(async () => {
    if (!gameData?.awayTeam?.masterTeamId) return;
    const teamMaster = fetchTeamMaster(sportsId, gameData?.awayTeam?.masterTeamId);
    return teamMaster;
  }, [gameData?.awayTeam?.masterTeamId]);

  return (
    <GameContext.Provider
      value={{
        sportsId,
        leagueId,
        gameId,
        locale,
        homePoint,
        awayPoint,
        homeTeamMaster,
        awayTeamMaster,
        ...data,
        timelineData,
        latestPoints,
        latestGamePoints,
        specifiedPlay
      }}
    >
      {children}
    </GameContext.Provider>
  );
};

export const useGameContext = () => {
  const context = useContext(GameContext);
  const { sportsId, leagueId, gameId } = context;
  if (!sportsId || !leagueId || !gameId) {
    throw new Error("GameContext is not provided");
  }

  return context;
};
