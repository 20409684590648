import { Timeline as TimelineType, PeriodGamePoint } from "models/timeline";
import { PeriodContract, PeriodScoreContract, TotalScore } from "models/game";
import { calculateTimelineData } from "assets/js/calculateTimelineData";
import { calcPeriodScores, calcTotalScore } from "./util";

export type GameScore = {
  timelineData: TimelineType[];
  latestPoints: { home: number; away: number };
  // テニスなどのセット内の取得ゲーム数
  latestGamePoints: PeriodGamePoint | undefined;
  specifiedPlay: TimelineType | undefined;
};

/**
 * 試合のピリオド毎のスコアを取得する。calculateTimelineDataをラップしている
 * @param timelines
 * @param sportsId
 * @param periodContract
 * @param periodScore
 * @returns
 */
export const getPeriodGameScore = (
  timelines: { [id: string]: TimelineType },
  sportsId: string,
  specifiedTimelineId?: string,
  periodContract?: PeriodContract[],
  periodScore?: PeriodScoreContract[],
  totalScore?: TotalScore
): GameScore => {
  const timelinePoint = calculateTimelineData(
    timelines,
    sportsId,
    specifiedTimelineId,
    periodContract
  );

  const score = calcTotalScore(periodScore, totalScore);
  const latestPoints = score ? score : timelinePoint.latestPoints;

  if (!periodContract || periodContract.length === 0 || !periodScore || periodScore.length === 0) {
    return { ...timelinePoint, latestPoints };
  }

  const latestGamePoints = calcPeriodScores(
    timelinePoint?.latestGamePoints,
    periodContract,
    periodScore
  );

  return {
    latestPoints,
    latestGamePoints,
    timelineData: timelinePoint.timelineData,
    specifiedPlay: timelinePoint.specifiedPlay
  };
};
