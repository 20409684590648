import { PeriodContract, PeriodScoreContract, TotalScore } from "models/game";
import { PeriodGamePoint } from "models/timeline";

export const calcTotalScore = (periodScores?: PeriodScoreContract[], totalScore?: TotalScore) => {
  if (!totalScore && !periodScores) return;

  if (totalScore) {
    return {
      home: totalScore.homeScore,
      away: totalScore.awayScore
    };
  }
  if (!periodScores || periodScores.length === 0) return;
  return calcTotalScoreByPeriod(periodScores);
};

const calcTotalScoreByPeriod = (periodScores: PeriodScoreContract[]) => {
  let homeTotalScore = 0;
  let awayTotalScore = 0;
  periodScores.forEach(score => {
    homeTotalScore += score.homeScore;
    awayTotalScore += score.awayScore;
  });
  return {
    home: homeTotalScore,
    away: awayTotalScore
  };
};

export const calcPeriodScores = (
  timelinePoint?: PeriodGamePoint,
  periodContract?: PeriodContract[],
  periodScores?: PeriodScoreContract[]
) => {
  if (!periodContract || periodContract.length === 0) return timelinePoint;
  if (!periodScores || periodScores.length === 0) return timelinePoint;
  const latestGamePoints = {} as PeriodGamePoint;
  periodContract.forEach(period => {
    if (period.periodType === "Break") return;
    const score = periodScores.find(s => s.periodId === period.id);
    const timelineScore = timelinePoint?.[period.id];
    latestGamePoints[period.id] = {
      home: score?.homeScore ?? timelineScore?.home ?? 0,
      away: score?.awayScore ?? timelineScore?.away ?? 0
    };
  });
  return latestGamePoints;
};
